<template>
  <div id="wizardSlide">
    <b-row class="mb-5">
      <b-col
        md="10"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("slider.create") }}</h1>
        <h5 class="text-primary">
          {{ $t("slider.subtitle") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-img
          v-if="languageService"
          height="20px"
          width="30px"
          :src="languageFlags(defaultLanguage)"
          class="mr-2"
        />
        <router-link to="/platform/slider">
          <b-button variant="danger">
            {{
              $t("dataGeneric.goBack")

            }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.save')"
        :back-button-text="$t('dataGeneric.previous')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- Información del slider tab -->
        <tab-content
          :title="$t('slider.info')"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("slider.info") }}
                </h5>
                <small class="text-muted">
                  {{ $t("slider.info2") }}
                </small>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  :rules="{ required: true, min: 3 }"
                >
                  <b-form-group :label="$t('dataGeneric.name') + ' *'">
                    <b-form-input
                      id="name"
                      v-model="name"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('slider.name')"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="!forceSponsor && hasAdSlides"
                md="6"
                class="d-flex align-items-center"
              >
                <b-form-checkbox
                  v-model="sponsor"
                  switch
                  @change="selectedType = 'URL'"
                >
                  {{ $t('adSlideCheck') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="match-height">
              <b-col class="d-flex">
                <b-form-group class="mb-2 mr-1">
                  <label class="d-flex ">
                    {{ $t("Image") }}
                    <feather-icon
                      v-b-tooltip.hover.top.v-primary="$t('slider.imageSize')
                      "
                      class="ml-2"
                      icon="InfoIcon"
                    />
                  </label>
                  <b-row>
                    <b-col
                      @dragover="event => event.preventDefault()"
                      @drop="addFiles($event, 'fileInput')"
                    >
                      <b-aspect :aspect="aspect">
                        <span
                          v-if="imageDataSrc == null
                          "
                          class="dropContainer placeholder embed-responsive-item cursor-pointer"
                          @click="chooseImage('fileInput')"
                        >
                          <feather-icon
                            icon="ImageIcon"
                            size="80"
                          />
                          {{ $t("media.dropFilesToCard") }}
                        </span>
                        <b-img
                          v-else
                          class="dropContainer cursor-pointer"
                          fluid
                          :src="imageDataSrc == null
                            ? ''
                            : imageDataSrc
                          "
                          @click="chooseImage('fileInput')"
                        />
                      </b-aspect>
                      <b-form-file
                        id="fileInput"
                        v-model="imageData"
                        accept="image/*"
                        class="mr-1"
                        style="display: none"
                        @change="event => getImageDimensions(event, 'web')"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col class="d-flex justify-content-center">
                <b-form-group class="mb-2 mr-1">
                  <label class="d-flex ">
                    {{ $t('slider.imageForMobile') }}
                    <feather-icon
                      v-b-tooltip.hover.top.v-primary="$t('slider.imageSizeMobile')
                      "
                      class="ml-2"
                      icon="InfoIcon"
                    />
                  </label>
                  <b-row>
                    <b-col
                      @dragover="event => event.preventDefault()"
                      @drop="addFiles($event, 'fileInputMobile')"
                    >
                      <b-aspect :aspect="aspectMob">
                        <span
                          v-if="imageDataMobileSrc == null
                          "
                          class="dropContainer placeholder embed-responsive-item cursor-pointer"
                          @click="chooseImage('fileInputMobile')"
                        >
                          <feather-icon
                            icon="ImageIcon"
                            size="80"
                          />
                          {{ $t("media.dropFilesToCard") }}
                        </span>
                        <b-img
                          v-else
                          class="dropContainer cursor-pointer "
                          fluid
                          :src="imageDataMobileSrc == null

                            ? ''
                            : imageDataMobileSrc
                          "
                          @click="chooseImage('fileInputMobile')"
                        />
                      </b-aspect>
                      <b-form-file
                        id="fileInputMobile"
                        v-model="imageDataMobile"
                        accept="image/*"
                        class="mr-1"
                        style="display: none"
                        @change="event => getImageDimensions(event, 'mobile')"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- AD SLIDE -->
        <tab-content
          v-if="sponsor"
          :title="$t('adSlide')"
          :before-change="validationFormInfo"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t("adSlide") }}
              </h5>
              <small class="text-muted">
                {{ $t("configAdSlide") }}
              </small>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="false">
              <b-form-group>
                <label>{{ $t('urlImpress') }}</label>
                <b-form-input
                  id="urlImpress"
                  v-model="callbackImpressionsUrl"
                  :placeholder="$t('urlImpress')"
                  autocomplete="new-password"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label>{{ $t('code.impresiones') }}<feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('code.impresionesInfo')
                  "
                  class="ml-1"
                  icon="InfoIcon"
                /></label>
                <b-form-input
                  id="impressions"
                  v-model="impressions"
                  type="number"
                  :placeholder="$t('code.impresiones')"
                  autocomplete="new-password"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group>
            <label class="d-flex align-items-center justify-content-between mt-1">
              {{ $t('percentageRotation') }}
              <b-button
                variant="primary"
                @click="applyPercentage()"
              >
                {{ $t('aplicar') }}
              </b-button></label>
            <b-row class="m-1">
              <b-col>
                <label>{{ name }} <feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('infoPercentage')
                  "
                  class="ml-1"
                  icon="InfoIcon"
                /></label>

                <div class="range-slider-container">
                  <label
                    for="range"
                    class="d-flex align-items-center"
                  ><b-form-input
                    v-model="percentageRotation"
                    class="mr-1"
                    type="number"
                    min="0"
                    max="100"
                  />%</label>
                  <b-form-input
                    id="range"
                    v-model="percentageRotation"
                    type="range"
                    min="0"
                    max="100"
                    class="custom-range range-input"
                    @input="saveRange()"
                  />
                  <div class="range-labels">
                    <span>0</span>
                    <span>50</span>
                    <span>100</span>
                  </div>
                </div>
              </b-col>
              <b-col
                v-for="(slide, i) in allAdSlide"
                :key="slide.node.id"
              >
                <label>{{ slide.node.name }}<feather-icon
                  v-b-tooltip.hover.top.v-primary="$t('infoPercentage')
                  "
                  class="ml-1"
                  icon="InfoIcon"
                /></label>
                <div class="range-slider-container">
                  <label
                    :key="key"
                    :for="'range-' + i"
                    class="d-flex align-items-center"
                  ><b-form-input
                    v-model="slide.node.percentageRotation"
                    class="mr-1"
                    type="number"
                    min="0"
                    max="100"
                  />%</label>
                  <b-form-input
                    :id="'range-' + i"
                    v-model="slide.node.percentageRotation"
                    type="range"
                    min="0"
                    max="100"
                    class="custom-range range-input"
                    @input="saveRange(slide.node)"
                  />
                  <div class="range-labels">
                    <span>0</span>
                    <span>50</span>
                    <span>100</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
        </tab-content>
        <!-- Archivos del contenido  tab -->
        <tab-content
          :title="$t('slider.data')"
          :before-change="validationFormImage"
        >
          <validation-observer
            ref="ArchivosRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("slider.data") }}
                </h5>
                <small class="text-muted">{{ $t("slider.dataInfo") }}</small>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <b-form-select
                  id="slideType"
                  v-model="selectedType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                >
                  <b-form-select-option value="URL">
                    URL
                  </b-form-select-option>
                  <b-form-select-option
                    v-if="type != 'SHO' || sponsor"
                    value="CON"
                  >
                    {{ $t("contents.content") }}
                  </b-form-select-option>
                  <b-form-select-option
                    v-if="type != 'SHO' || sponsor"
                    value="CAT"
                  >
                    {{ $t("purchases.category") }}
                  </b-form-select-option>
                  <b-form-select-option
                    v-if="isShop"
                    value="CAP"
                  >
                    {{ $t('slider.productCategory') }}
                  </b-form-select-option>
                  <b-form-select-option
                    v-if="isShop"
                    value="PRO"
                  >
                    {{ $t('producto') }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row v-if="selectedType == 'URL'">
              <b-col
                cols="12"
                class="mb-2"
              >
                <validation-provider
                  v-slot="validationContext"
                  name="url"
                  rules="required|url"
                >
                  <b-form-group label="URL">
                    <b-form-input
                      id="url"
                      v-model="url"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      placeholder="URL"
                    />
                    <small class="text-danger">{{ confirmRules(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="selectedType == 'CON'">
              <b-col class="mb-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="contentName"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('contents.content')"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-input
                      id="content"
                      v-model="contentName"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('modal.content')"
                      autocomplete="new-password"
                      disabled
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
                style="place-self: center;"
              >
                <b-button
                  variant="info"
                  class="mb-1"
                  @click="selectElement(0)"
                >
                  {{ $t("modal.content") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="selectedType == 'CAT'">
              <b-col class="mb-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="category"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('purchases.category')"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-input
                      id="category"
                      v-model="categoryName"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('modal.category')"
                      autocomplete="new-password"
                      disabled
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
                style="place-self: center;"
              >
                <b-button
                  variant="info"
                  class="mb-1"
                  @click="selectElement(1)"
                >
                  {{
                    $t("modal.category")
                  }}
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="selectedType == 'CAP'">
              <b-col class="mb-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="categoryProd"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('slider.productCategory')"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-input
                      id="categoryProd"
                      v-model="categoryProdName"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('slider.productCategory')"
                      autocomplete="new-password"
                      disabled
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
                style="place-self: center;"
              >
                <b-button
                  variant="info"
                  class="mb-1"
                  @click="selectElement(2)"
                >
                  {{ $t('select') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="selectedType == 'PRO'">
              <b-col class="mb-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="product"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('producto')"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-input
                      id="product"
                      v-model="productName"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('producto')"
                      autocomplete="new-password"
                      disabled
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
                style="place-self: center;"
              >
                <b-button
                  variant="info"
                  class="mb-1"
                  @click="selectElement(3)"
                >
                  {{ $t('select') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="1">
                <b-form-group
                  :label="$t('dataGeneric.activeQ')"
                  label-for="name"
                >
                  <b-form-checkbox
                    id="patymentTerms"
                    v-model="isActive"
                    :disabled="isActive ? false : !activeSlide"
                    switch
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="!activeSlide"
                style="align-self: center;"
              >
                <div class="active-info">
                  <p>{{ messageDeactive }}</p>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="isActive">
              <b-col>
                <b-form-group class="mb-2 mr-1">
                  <label class="mb-1">
                    {{ $t('contents.datePublication') }}
                    <feather-icon
                      v-if="publishDate"
                      class="text-danger cursor-pointer ml-1"
                      icon="XOctagonIcon"
                      size="15"
                      @click="publishDate = null"
                    />
                  </label>
                  <flat-pickr
                    v-model="publishDate"
                    class="form-control"
                    :placeholder="$t('contents.datePublication')"
                    :config="{
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'Y-m-d H:i:S',
                      minDate: 'today'
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-2 mr-1">
                  <label class="mb-1">
                    {{ $t('contents.dateFinishing') }}
                    <feather-icon
                      v-if="expirationDate"
                      class="text-danger cursor-pointer ml-1"
                      icon="XOctagonIcon"
                      size="15"
                      @click="expirationDate = null"
                    />
                  </label>
                  <flat-pickr
                    v-model="expirationDate"
                    class="form-control"
                    :placeholder="$t('contents.dateFinishing')"
                    :config="{
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'Y-m-d H:i:S',
                      minDate: publishDate
                    }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>

    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.content')"
    >
      <div class="d-block text-center">
        <content-selector @info="SelectContentInfo" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="cat-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="categorySelectorTable"
          @confirm-selection="CategorySelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="cat-prod-modal"
      size="xl"
      hide-footer
      :title="$t('slider.selectShopCategory')"
    >
      <div class="d-block text-center">
        <category-product-selector
          :columns="categorySelectorTable"
          @confirm-selection="CategoryProductSelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="prod-modal"
      size="xl"
      hide-footer
      :title="$t('slider.selectProduct')"
    >
      <div class="d-block text-center">
        <product-selector
          :columns="categorySelectorTable"
          @confirm-selection="ProductSelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'

import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BFormFile,
  BFormSelectOption,
  BFormSelect,
  BFormCheckbox,
  VBTooltip,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAspect,
  BButton,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import {
  showToast, messageError, base64Encode, utf8ToB64,
} from '@/store/functions'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import languageFlags from '@/@core/utils/languageFlags'
import ContentSelector from '../../common/ContentSelector.vue'
import CategorySelector from '../../common/CategorySelector.vue'
import ProductSelector from '../../common/ProductSelector.vue'
import CategoryProductSelector from '../../common/CategoryProductSelector.vue'

export default {
  components: {
    BFormFile,
    BImg,
    BFormSelectOption,
    BButton,
    BAspect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    flatPickr,
    BFormSelect,
    CategoryProductSelector,
    BRow,
    BCol,
    ProductSelector,
    BFormGroup,
    BFormInput,
    BOverlay,
    CategorySelector,
    ContentSelector,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      key: 1,
      messageDeactive: '',
      activeSlide: true,
      percentageRotation: 0,
      callbackImpressionsUrl: '',
      impressions: 0,
      position: 0,
      required,
      userData: getUserData(),
      imageData: null,
      imageDataMobile: null,
      show: false,
      isActive: false,
      isShop: false,
      name: null,
      url: null,
      aspect: 1920 / 575,
      aspectMob: 1170 / 804,
      id: null,
      imageDataSrc: null,
      imageDataMobileSrc: null,
      selectedType: 'URL',
      contentName: null,
      categoryProdName: null,
      categoryProdId: null,
      categoryName: null,
      defaultLanguage: '',
      languageService: false,
      categoryId: null,
      productName: null,
      productId: null,
      elementId: null,
      catTableData: null,
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
      ],
      totalSlideCount: null,
      totalSlide: null,
      type: this.$route.params.type,
      sponsor: false,
      allAdSlide: [],
      forceSponsor: false,
      hasAdSlides: false,
      expirationDate: null,
      publishDate: null,
    }
  },
  watch: {
    selectedType() {
      switch (this.selectedType) {
        case 'CAT':
          this.contentName = ''
          this.elementId = ''
          this.activeSlide = false
          this.isActive = false
          this.messageDeactive = ''

          break
        case 'CON':
          this.categoryName = ''
          this.categoryId = ''
          this.activeSlide = false
          this.isActive = false
          this.messageDeactive = ''
          break
        default:
          this.activeSlide = true

          break
      }
    },

    imageData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataSrc = value
            })
            .catch(() => {
              this.imageDataSrc = null
            })
        } else {
          this.imageDataSrc = null
        }
      }
    },
    imageDataMobile(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataMobileSrc = value
            })
            .catch(() => {
              this.imageDataMobileSrc = null
            })
        } else {
          this.imageDataMobileSrc = null
        }
      }
    },
  },
  mounted() {
    try {
      this.isShop = this.userData.profile.client.isShop
      this.hasAdSlides = this.userData.profile.client.hasAdSlides
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
    this.getDefaultLanguage()
    this.position = this.$route.params.position
    if (this.$route.name === 'slide-create-sponsor') {
      this.forceSponsor = true
      this.sponsor = this.forceSponsor
    }
    this.allCategory()
    this.totalSlideSlider()
    this.checkPercentage(this.position)
  },

  methods: {
    fechaFormato(value) {
      const fecha = new Date(value)
      return value != null ? fecha : value
    },
    languageFlags,
    addFiles(event, type) {
      event.dataTransfer.files.forEach(element => {
        switch (type) {
          case 'fileInput':
            this.imageData = element
            break
          case 'fileInputMobile':
            this.imageDataMobile = element
            break

          default:
            break
        }
      })
      event.preventDefault()
    },
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 defaultLanguage
                 availableLanguages
             }
         }
         `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client
          try {
            const arr = availableLanguages.split('|')
            this.languageService = arr.length > 1
            // eslint-disable-next-line no-empty
          } catch (error) {
          }
          this.defaultLanguage = res.data.data.client.defaultLanguage
        })
        .catch(() => {
        })
    },
    fix(fixDta) {
      const keyName = Object.keys(fixDta.failedRules)
      if (keyName === 'required') {
        return this.$t('required')
      }
      if (keyName === 'min') {
        return this.$t('cont3char')
      }
      return ''
    },
    fixUrl(fixDta) {
      const keyName = Object.keys(fixDta.failedRules)
      if (keyName === 'required') {
        return this.$t('required')
      }
      if (keyName === 'url') {
        return this.$t('validUrl')
      }
      return ''
    },
    allCategory() {
      axios
        .post('', {
          query: `
            query{  
            allCategories(client:"${this.userData.profile.client.id}"){
              edges{
                node{
                  id
                  name
                  image
                  mediaLocation
                  imageUrl
                }
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.catTableData = res.data.data.allCategories.edges
        })
    },
    saveRange(node = null) {
      let percentages = []
      let id = null
      let percentageRotation = null
      if (node) {
        id = node.id
        percentageRotation = parseFloat(parseFloat(node.percentageRotation).toFixed(2))
      } else {
        id = 'new'
        percentageRotation = parseFloat(parseFloat(this.percentageRotation).toFixed(2))
      }
      const adSlides = this.allAdSlide.slice()
      adSlides.push({ node: { id: 'new', percentageRotation: this.percentageRotation } })
      percentages = this.adjustPercentages(adSlides, id, percentageRotation)
      percentages.forEach(element => {
        if (element.node.id === 'new') {
          this.percentageRotation = parseFloat(parseFloat(element.node.percentageRotation).toFixed(2))
        } else {
          const slide = this.allAdSlide.find(s => s.node.id === element.node.id)
          if (slide) {
            slide.node.percentageRotation = parseFloat(parseFloat(element.node.percentageRotation).toFixed(2))
          }
        }
      })
      this.key += 1
    },
    applyPercentage() {
      const adSlides = this.allAdSlide.slice()
      adSlides.push({ node: { id: 'new', percentageRotation: this.percentageRotation } })
      adSlides.forEach(element => {
        if (element.node.id === 'new') {
          this.saveRange()
        } else {
          this.saveRange(element.node)
        }
      })
    },
    adjustPercentages(adSlide, elementId, newElementPercentage) {
      const elementIndex = adSlide.findIndex(element => element.node.id === elementId)

      const updatedSlide = adSlide.map(element => ({
        ...element,
        node: { ...element.node },
      }))
      updatedSlide[elementIndex].node.percentageRotation = newElementPercentage

      const remainingPercentage = 100 - newElementPercentage
      if (remainingPercentage < 0) {
        updatedSlide[elementIndex].node.percentageRotation = 100
        return updatedSlide.map((element, index) => {
          if (index !== elementIndex) {
            return {
              ...element,
              node: {
                ...element.node,
                percentageRotation: 0,
              },
            }
          }
          return element
        })
      }
      const otherElementsTotalPercentage = updatedSlide.reduce((sum, element, index) => {
        if (index !== elementIndex) {
          return sum + parseFloat(element.node.percentageRotation || 0) // Asegúrate de convertir a número
        }
        return sum
      }, 0)

      const adjustedSlide = updatedSlide.map((element, index) => {
        if (index !== elementIndex) {
          return {
            ...element,
            node: {
              ...element.node,
              percentageRotation: (element.node.percentageRotation / otherElementsTotalPercentage) * remainingPercentage,
            },
          }
        }
        return element
      })

      return adjustedSlide
    },
    updatePercentages() {
      let query = 'mutation{'
      this.allAdSlide.forEach((element, i) => {
        query += `slide_${i + 1}: updateAdSlide(id:"${element.node.id}",input:{percentageRotation:${element.node.percentageRotation}}){
                  adSlide{
                    id
                  }
                }`
      })
      query += '}'
      axios
        .post('', {
          query,
        }).then(res => {
          messageError(res, this)
        }).catch(err => {
          console.log(err)
        })
    },
    formSubmitted() {
      this.show = true
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      const dateIn = this.fechaFormato(this.publishDate)
      const dateFin = this.fechaFormato(this.expirationDate)

      axios
        .post('', {
          variables: {
            publishDate: dateIn,
            expirationDate: dateFin,
            callbackImpressionsUrl: this.callbackImpressionsUrl,
          },
          query: `
            mutation($publishDate: DateTime,$expirationDate: DateTime,  ${this.sponsor ? '# $callbackImpressionsUrl: String' : ''}
            ){
            ${this.sponsor ? 'createAdSlide' : 'createSlide'}(input:{
            client:"${this.userData.profile.client.id}", 
            slideType:${this.selectedType},
            ${this.url == null ? '' : `url:"${this.url}",`}
            ${this.elementId == null ? '' : `elementId:"${this.elementId}",`}
            ${this.categoryId == null ? '' : `categoryId:"${this.categoryId}",`}
            ${this.productId == null ? '' : `product:"${this.productId}",`}
            ${this.categoryProdId == null ? '' : `categoryProducts:"${this.categoryProdId}",`}
            name:"${this.name}",
            isActive:${this.isActive},
            publishDate: $publishDate,
            expirationDate: $expirationDate,
            order:${this.position}
            ${this.sponsor ? `
              #callbackImpressionsUrl: $callbackImpressionsUrl,
              sponsor:${this.sponsor},
              percentageRotation:${this.percentageRotation},
              impressions:${this.impressions},
              position:${this.position}                  
            ` : ''}
            
            }){
            ${this.sponsor ? 'adSlide' : 'slide'}{
              name
              id
            }
          }
        } 
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.id = this.sponsor ? res.data.data.createAdSlide.adSlide.id : res.data.data.createSlide.slide.id

          const config = {
            timeout: 1000 * 60 * 30,
          }
          const data = new FormData()
          const query = `
                mutation{
                  updateSlide(id:"${this.id}",input:{
                    client: "${this.userData.profile.client.id}",
                  }){
                        slide{
                          id
                          name    
                         image    
                        }              
                      }
                    }
              `
          data.append('query', query)
          data.append('image', this.imageData)
          data.append('image_mobile', this.imageDataMobile)
          axios
            .post('', data, { headers }, config)
            .then(createResponse => {
              messageError(createResponse, this)
              const todos = []
              for (let i = 0; i < this.totalSlideCount; i += 1) {
                todos.push(this.totalSlide[i].node.id)
              }
              todos.push(this.id)
              axios
                .post('', {
                  query: `
                  mutation{
                  updateSlider(id:"${this.$route.params.idSlider}",input:{
                    slide:[${todos}]

                    }){
                  slider{
                    id
                    slide{
                      edges {
                        node {
                          id
                          name
                        }
                      }

                    }
                  }
                }
                  }
                `,
                })
                .then(result => {
                  messageError(result, this)

                  showToast(this.$t('success'), 1, this)
                  this.show = false
                  this.$router.push('/platform/slider')
                  if (this.allAdSlide.length !== 0) {
                    this.updatePercentages()
                  }
                })
                .catch(err => {
                  console.log(err)
                  showToast(this.$t('slider.errorAdd'), 0, this)
                  this.show = false
                })
            })
            .catch(() => {
              showToast(this.$t('slider.errorImg'), 0, this)

              this.show = false
            })
        })
        .catch(res => {
          console.log(res)
          showToast(this.$t('error'), 0, this)
          this.show = false
        })
    },
    getImageDimensions(event, type) {
      let maxWidth = ''
      let maxHeight = ''

      if (type === 'web') {
        maxWidth = 1920
        maxHeight = 575
      } else {
        maxWidth = 1170
        maxHeight = 804
      }

      const file = event.target.files[0]
      if (!file) return

      const reader = new FileReader()
      reader.onload = e => {
        const img = new Image()
        img.onload = () => {
          const { width } = img
          const { height } = img

          if (width !== maxWidth || height !== maxHeight) {
            this.$swal({
              title: this.$t('warning'),
              text: this.$t('recomendedSize', {
                width, height, maxWidth, maxHeight,
              }),
              icon: 'warning',
              confirmButtonText: this.$t('next'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        }
        img.src = e.target.result
      }
      reader.readAsDataURL(file)
    },
    totalSlideSlider() {
      axios
        .post('', {
          query: `
         query{
            slider(id:"${this.$route.params.idSlider}"){
              edges {
                node {
                  id
                  slide {
                    totalCount
                    edges {
                      node {
                        id
                        name
                        order
                        adslide {
                          id
                          impressions
                          consumed
                          sponsor
                          position
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)
          this.totalSlideCount = result.data.data.slider.edges[0].node.slide.totalCount
          this.totalSlide = result.data.data.slider.edges[0].node.slide.edges
          this.totalSlide = this.totalSlide.sort((a, b) => a.node.order - b.node.order)
        })
        .catch(err => {
          console.log(err)
        })
    },
    checkPercentage() {
      const idSlider = utf8ToB64(`id:${this.$route.params.idSlider}`)
      const query = `{
            allAdSlide(position:${this.position}, slide:"${idSlider}"){
            totalCount
              edges {
                node {
                  id
                  name
                  percentageRotation
                  slidePtr {
                    slide {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }`
      axios
        .post('', {
          query,
        }).then(res => {
          messageError(res, this)
          const { allAdSlide } = res.data.data
          const { totalCount } = allAdSlide
          const countSlide = totalCount + 1
          this.percentageRotation = parseFloat((100 / countSlide).toFixed(0))
          this.allAdSlide = allAdSlide.edges.map(element => {
            const newPercentage = parseFloat((100 / countSlide).toFixed(0))
            return { ...element, node: { ...element.node, percentageRotation: newPercentage } }
          })
        })
    },
    validationFormImage() {
      return new Promise((resolve, reject) => {
        this.$refs.ArchivosRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    chooseImage(imageClicked) {
      this.imageClicked = imageClicked
      document.getElementById(imageClicked).click()
    },
    selectElement(elemento) {
      switch (elemento) {
        case 0:
          this.$refs['my-modal'].show()
          break
        case 1:
          this.$refs['cat-modal'].show()

          break
        case 2:
          this.$refs['cat-prod-modal'].show()

          break
        case 3:
          this.$refs['prod-modal'].show()

          break

        default:
          break
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
      this.$refs['cat-modal'].hide()
      this.$refs['cat-prod-modal'].hide()
      this.$refs['prod-modal'].hide()
    },
    confirmRules(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('tabUser.urlRequired')
      }
      if (existeClave.includes('url')) {
        return this.$t('tabUser.urlValid')
      }
      return ''
    },
    SelectContentInfo(data) {
      const [id, name, state] = data
      this.contentName = name
      this.elementId = id
      const stateBool = state === 'GRE'
      if (this.selectedType === 'CON') {
        this.activeSlide = stateBool
        this.messageDeactive = this.$t('deacCont')
      } else this.activeSlide = true

      this.isActive = stateBool ? this.isActive : false

      this.hideModal()
    },

    CategorySelection(data) {
      this.categoryName = data.node.name
      this.categoryId = data.node.id
      const state = data.node.state === 'GRE'
      if (this.selectedType === 'CAT') this.activeSlide = state
      else this.activeSlide = true

      this.messageDeactive = this.$t('deacCateg')
      this.isActive = data.node.isActive ? this.isActive : false
      this.hideModal()
    },
    ProductSelection(data) {
      this.productName = data.node.name
      this.productId = data.node.id
      this.hideModal()
    },
    CategoryProductSelection(data) {
      this.categoryProdName = data.node.name
      this.categoryProdId = data.node.id
      this.hideModal()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#wizardSlide .modal {
  overflow-y: auto;
}

/*
Full screen Modal
*/
#wizardSlide .fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 768px) {
  #wizardSlide .fullscreen-modal .modal-dialog {
    width: 750px;
  }
}

@media (min-width: 992px) {
  #wizardSlide .fullscreen-modal .modal-dialog {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #wizardSlide .fullscreen-modal .modal-dialog {
    width: 1170px;
  }
}

#wizardSlide .dropContainer {
  border: 3px dashed;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#inputColor {
  visibility: hidden;
}

#wizardSlide .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#wizardSlide .placeholder:hover {
  background: #e0e0e0;
}

#wizardSlide .file-input {
  display: none;
}

#wizardSlide .cBox {
  align-items: center;
}

#wizardSlide .active-info {
  display: flex;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

.range-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.range-input {
  width: 100%;
  margin: 20px 0;
  -webkit-appearance: none;
  background: transparent;
}

.range-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: #7367f0;
  border-radius: 5px;
}

.range-input::-webkit-slider-thumb {
  border: 1px solid #000000;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}

.range-input:focus::-webkit-slider-runnable-track {
  background: #7367f0;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;
}
</style>
